import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Col, Grid, Row} from '../../components/FlexBox/FlexBox';
import DrawerBox from "../../components/DrawerBox/DrawerBox";
import {Details, Text} from "../BookingDetailForm/BookingDetailsForm.style";
import Button, {KIND} from "../../components/Button/Button";
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import {baseSelectOverrides, DeviderLine, GoogleMapsWrapper} from "./BookingStyle";
import {GoogleMap, LoadScript, Marker} from '@react-google-maps/api';
import {get, getPURE, post} from "../../helper/fetch";
import {toast} from 'react-toastify';
import Popup from 'reactjs-popup';
import './BookingStyle.css';
import requested from '../../components/requested.gif';
import BookingStatus from "../../components/BookingStatus/BookingStatus";
import Extras from "../../components/Extras/Extras";
import AddressDetails from "../../components/AddressDetails/AddressDetails";
import Chat from "../../components/Chat/Chat";
import {FormFields, FormLabel} from '../../components/FormFields/FormFields';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from '../../components/Select/Select';
import ExtraPill from "../../components/ExtraPill/ExtraPill";
import CleanerReceipt from "../../components/CleanerReceipt/CleanerReceipt";
import {ChatAction, Message} from '../../components/Chat/Chat';
import CleanerCheckList from "../../components/CleanerCheckList/CleanerCheckList";
import {
  airbnbCleanChecklist,
  CheckListProps,
  generalCleanChecklist,
  moveOutCleanChecklist
} from "../../settings/bookingTypes";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
dayjs.extend(isSameOrBefore);

type BookingExtra = {
  name: string;
  id: number;
  price?: number;
}


type BookingDateAndTime = {
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
  bookingDate: string;
}

type Receipt = {
  total: number;
  subTotal: number;
  taxes: number;
  fees: number;
  cleanerGets: number;
  couponCovers: number;
}

type BookingDetails = {
  id: number;
  listingId?: number;
  clientName: string;
  date: Date;
  currentState: string;
  cleaningTime: string;
  recurrance?: string,
  canExtendHours?: boolean,

  totalAmount: number,
  coupon?: {
    percentage: number;
    amount: number;
    code: number;
  },
  extras: BookingExtra[],
  cleaningSupplies: number,
  address: string;
  apartmentNumber: string;
  entranceInfo: string;
  parkingInfo: string;
  otherInfo: string;
  numberOfRooms?: number;
  numberOfBathrooms?: number;
  squareFootage?: number;
  sizeIncludesBasement?: boolean;

  coordinates: {
    lat: number,
    lng: number
  },

  messagesList: Message[];
  messages: [];
  conversationId: number;
  startedAt: string;
  stoppedAt: string;
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
}

const allHours = [{value: 6, label: 6},
  {value: 7, label: 7},
  {value: 8, label: 8},
  {value: 9, label: 9},
  {value: 10, label: 10},
  {value: 11, label: 11},
  {value: 12, label: 12},
  {value: 13, label: 13},
  {value: 14, label: 14},
  {value: 15, label: 15},
  {value: 16, label: 16},
  {value: 17, label: 17},
  {value: 18, label: 18},
  {value: 19, label: 19},
  {value: 20, label: 20},
  {value: 21, label: 21},
  {value: 22, label: 22},
];

const Booking: React.FC<any> = () => {
  const {id} = useParams<{ id: string }>();
  useEffect(() => {
    openBookingDetailDrawer(id);
  }, []);

  const openBookingDetailDrawer = async (bookingId) => {
    try {
      const bookingDetails = await get('/bookings/' + bookingId);
      setBookingDetails(bookingDetails);
      setMessagesList(bookingDetails.messages);
      const extras = await get('/extras');
      let extraSet = new Set<BookingExtra>();
      for (let e of extras) {
        extraSet.add(e);
      }
      setExtras(extraSet);
      //populate newExtras here
      let newextraSet = new Set<BookingExtra>();
      for (let e of bookingDetails.extras) {
        newextraSet.add(e);
      }
      setNewExtras(newextraSet);
      setExtrasReady(true);
    } catch (e) {
      //TODO: do this shit
    }
  }

  const [bookingDetails, setBookingDetails] = useState<BookingDetails>(null);
  const [messageLoading, setMessageLoading] = useState(false);
  const [messagesList, setMessagesList] = useState<Message[]>([] as Message[]);
  const [newMessage, setNewMessage] = useState('');
  const [confirmStartModalOpen, setConfirmStartModalOpen] = useState(false);
  const [confirmRejectModalOpen, setConfirmRejectModalOpen] = useState(false);
  const [editBookingModalOpen, setEditBookingModalOpen] = useState(false);
  const [newChatAction, setNewChatAction] = useState({} as ChatAction);
  const [newDateTime, setNewDateTime] = useState({} as BookingDateAndTime);
  const [newExtras, setNewExtras] = useState(new Set<BookingExtra>());
  const [extras, setExtras] = useState(new Set<BookingExtra>());
  const [extrasReady, setExtrasReady] = useState(false);
  const [newReceipt, setNewReceipt] = useState({} as Receipt);

  useEffect(() => {
    calcReceipt();
  }, [newDateTime, newExtras]);

  const submitNewMessageToBooking = async (newMessage, conversationId) => {
    //TODO: nearly guaranteed to be total garbage. Need to use reducer instead of useState
    setMessageLoading(true);
    setNewMessage('');
    try {
      const res = await submitNewMessage(newMessage, conversationId);
      if (res) {
        const msg: Message = {
          createdAt: new Date(),
          sender: {
            role: 'cleaner', name: ''
          },
          content: newMessage
        };
        setMessagesList(messagesList => {
          return [...messagesList, msg]
        });
      }
    } catch (e) {
      //TODO: error msg please
    }
    setMessageLoading(false)
  };

  const submitNewMessage = async (newMessage, conversationId) => {
    try {
      const res = await post('/bookings/conversations/' + conversationId, {
        newMessage: newMessage,
        bookingId: bookingDetails.id
      });
      if (res.status !== 201) {
        toast.error('Could not add message');
        return false;
      }
      toast.success('Added message');
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }

  };

  const addNotification = async (bookingId) => {
    try {
      const resp = await post(`/notifications`, {booking_id: bookingId});
      if (resp.status !== 201) {
        toast.error('Could not notify admins about this booking');
      }
      toast.success('Notified admins about this booking!');
    }catch (e) {
      toast.error('Could not notify admins about this booking');
    }
  }

  const startCleaning = async () => {
    try {
      const res = await post(`/bookings/${bookingDetails.id}/start`, {when: dayjs().format('YYYY MM DD h:mm A')});
      if (res.status !== 200) {
        toast.error('Oops! Could not add START booking contact administrators, but do proceed with the cleaning we\'ll sort it out');
        return false;
      }
      toast.success('Started!');
      // setStarted(true);
      return true;
    } catch (e) {
      toast.error('Could not add START booking contact administrators ASAP');
      return false;
    }
  };

  const stopCleaning = async () => {
    try {
      const res = await post(`/bookings/${bookingDetails.id}/stop`, {when: dayjs().format('YYYY MM DD h:mm A')});
      if (res.status !== 200) {
        toast.error('Oops! Could not add STOP booking contact administrators, we\'ll sort it out');
        return false;
      }
      toast.success('Stopped!');
      // setStopped(true);
      return true;
    } catch (e) {
      toast.error('Oops! Could not add STOP booking contact administrators, we\'ll sort it out');
      return false;
    }
  };

  const acceptCleaningRequest = async () => {
    getPURE(`/bookings/${bookingDetails.id}/accept`)
        .then(res => {
          if (res.status !== 200) {
            toast.error('Could not add ACCEPT booking contact administrators ASAP');
            return false;
          }
          toast.success('ACCEPTED booking request');
          setBookingDetails({...bookingDetails, currentState: 'accepted'})
          return true;
        })
        .catch(e => {
          console.log(e);
          toast.error('Could not add ACCEPT booking contact administrators ASAP');
          return false;
        });
  };

  const rejectCleaningRequest = async () => {
    getPURE(`/bookings/${bookingDetails.id}/reject`)
        .then(res => {
          if (res.status !== 200) {
            toast.error('Could not add REJECT booking contact administrators ASAP');
            return false;
          }
          toast.success('REJECTED booking request');
          setBookingDetails({...bookingDetails, currentState: 'rejected'})
          return true;
        })
        .catch(e => {
          toast.error('Could not add REJECT booking contact administrators ASAP');
          return false;
        });
  };

  const createBookingAction = async (approved=false) => {
    let extraArray = [];
    newExtras.forEach(extra => {
      extraArray.push(extra);
    });

    let newAction = {
      conversationId: bookingDetails.conversationId,
      details: {
        bookingDateAndTime: newDateTime,
        extras: extraArray
      },
    };
    post(`/bookings/${bookingDetails.id}/actions?approved=${approved}`, newAction)
        .then(res => {
          if (res.status !== 201) throw new Error('Could not calc receipt');
          toast.success(`Created booking change ${approved  ? '' :'proposal and sent it to the client'}.`);
          setEditBookingModalOpen(false);
          openBookingDetailDrawer(bookingDetails.id);
        })
        .catch(e => {
          toast.error(`Could not create booking change ${approved  ? '' :'proposal'}. Please contact administrators ASAP`);
        });
  }

  function handleTimeChange({value}, type) {
    switch (type) {
      case 'startHour':
        setNewDateTime(ps => {
          return {...ps, startHour: value[0].value}
        });
        break;
      case 'endHour':
        setNewDateTime({...newDateTime, endHour: value[0].value});
        break;
      case 'startMinute':
        setNewDateTime({...newDateTime, startMinute: value[0].value});
        break;
      case 'endMinute':
        setNewDateTime({...newDateTime, endMinute: value[0].value});
        break;
      default:
        break;
    }
    console.log(JSON.stringify(newDateTime, null, 2))
  }

  const addExtra = (extra) => {
    // newExtras.add(extra)
    let extrasWithNew = [];
    for (const newExtrasKey of Array.from(newExtras)) {
      extrasWithNew.push(newExtrasKey);
    }
    extrasWithNew.push(extra)
    setNewExtras(new Set(extrasWithNew))
  }
  const removeExtra = (extra) => {
    let extrasWithoutDeleted = [];
    for (const newExtrasKey of Array.from(newExtras)) {
      if (newExtrasKey.id !== extra.id) {
        extrasWithoutDeleted.push(newExtrasKey);
      }
    }
    setNewExtras(new Set(extrasWithoutDeleted));
  }

  const calcReceipt = () => {
    if (!newDateTime.startHour || !newDateTime.endHour) return;
    let from = newDateTime.startHour;
    if (newDateTime.startMinute > 0) from += 0.5;
    let to = newDateTime.endHour;
    if (newDateTime.endMinute > 0) to += 0.5;
    const hours = to - from;
    let extraIds = [];
    if (newExtras.size > 0) {
      extraIds = Array.from(newExtras);
    }
    post('/bookings/receipt', {
      hours: hours,
      extras: extraIds,
      couponCode: null,
    })
        .then(res => {
          if (res.status !== 200) throw new Error('Could not calc receipt');
          return res.json();
        })
        .then(r => {
          setNewReceipt({
            subTotal: r.subTotal,
            fees: r.fees,
            taxes: r.taxes,
            couponCovers: r.couponCovers,
            total: r.total,
            cleanerGets: r.cleanerGets
          });
        })
        .catch(e => {
        });
  }

  const setAllPills = () => {
    let pills = [];
    if (!extras || !newExtras) return [];
    let extraIds = new Set<number>();
    newExtras.forEach(ne => {
      extraIds.add(ne.id);
    })
    extras.forEach(e => {
      pills.push(<ExtraPill
          id={e.id}
          name={e.name}
          content={""/*'+$' + e.price*/}
          checked={extraIds.has(e.id)}
          disabled={false}
          onChecked={addExtra}
          onUnChecked={removeExtra}
      />);
    })
    return pills;
  }

  const newExtraHas = (e) => {
    newExtras.forEach(ne => {
      if (ne.id === e.id) {
        return true;
      }
    })
    return false;
  }

  const openEditBookingModal = () => {
    setEditBookingModalOpen(true);
    let newextraSet = new Set<BookingExtra>();
    for (let e of bookingDetails.extras) {
      newextraSet.add(e);
    }
    setNewExtras(newextraSet);
    setNewDateTime({
      startHour: bookingDetails.startHour, startMinute: bookingDetails.startMinute,
      endHour: bookingDetails.endHour, endMinute: bookingDetails.endMinute,
      bookingDate: dayjs(bookingDetails.date).format()
    } as BookingDateAndTime)
    setNewChatAction({} as ChatAction);
  }

  function couponCovers() {
    if (bookingDetails.coupon && bookingDetails.coupon.percentage) {
      return `(${bookingDetails.coupon.percentage}% coupon was used by the client)`;
    }
  }

  const cleaningCommeneced = () => (bookingDetails.startedAt === null
      && bookingDetails.currentState !== 'requested'
      && bookingDetails.currentState !== 'cancelled'
      && bookingDetails.currentState !== 'paid')
  && (dayjs().isBefore(dayjs(bookingDetails.date), 'date') || dayjs().isSame(dayjs(bookingDetails.date), 'date'));

  function generateCheclist() {
    let checklist: CheckListProps[] = [];
    //itterate over booking.extras
    //if extra is a checklist item, add it to checklist
    for (const extra of bookingDetails.extras) {
      if(extra.id === 12) {
        checklist = moveOutCleanChecklist as CheckListProps[];
      }else if(extra.id === 199 || extra.id === 200) {
        checklist = airbnbCleanChecklist as CheckListProps[];
      }else{
        checklist = generalCleanChecklist as CheckListProps[];
      }
    }
    return <ErrorBoundary fallback={<h1>Sorry, there was an error, we can't show you cleaning checklist atthis time</h1>}>
      <CleanerCheckList
        checklist={checklist}
      />
    </ErrorBoundary>

  }

  return (
      <>
        {bookingDetails && (
            <>
              <Popup
                  open={confirmStartModalOpen}
                  modal={true}
                  nested
              >
                <div className="modal">
                  <button className="close" onClick={e => {
                    e.preventDefault();
                    setConfirmStartModalOpen(false);
                  }
                  }>
                    &times;
                  </button>
                  <div className="header"> Start your cleaning Cleaning</div>
                  <div className="content">
                    {' '}
                    This action will notify client that you've started the cleaning. Proceed?
                  </div>
                  <div className="actions">
                    <Button
                        title={'Reject'}
                        onClick={async e => {
                          e.preventDefault();
                          // setStarted(true);
                          await startCleaning();
                          setConfirmStartModalOpen(false);
                          window.location.reload();
                        }
                        }
                    >Start!</Button>
                  </div>
                </div>
              </Popup>

              <Popup
                  open={confirmRejectModalOpen}
                  modal={true}
                  nested
              >
                <div className="modal">
                  <button className="close" onClick={e => {
                    e.preventDefault();
                    setConfirmRejectModalOpen(false);
                  }
                  }>
                    &times;
                  </button>
                  <div className="header"> Reject booking request</div>
                  <div className="content">
                    {' '}
                    Are you sure you want to reject this cleaning request?
                  </div>
                  <div className="actions">
                    <Button
                        title={'Reject'}
                        kind={KIND.minimal}
                        onClick={async e => {
                          e.preventDefault();
                          await rejectCleaningRequest();
                          setConfirmRejectModalOpen(false);
                        }
                        }
                        overrides={{
                          BaseButton: {
                            style: ({$theme}) => ({
                              height: '48px',
                              borderTopLeftRadius: '3px',
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                              borderBottomLeftRadius: '3px',
                              color: $theme.colors.red400,
                            }),
                          },
                        }}
                    >Reject</Button>
                  </div>
                </div>
              </Popup>

              <Popup
                  open={editBookingModalOpen}
                  modal={true}
                  onOpen={() => {
                  }}
                  onClose={() => {
                    setEditBookingModalOpen(false)
                  }}
                  nested
                  className={'booking-action'}
              >
                <div className="modal">
                  <button className="close" onClick={e => {
                    e.preventDefault();
                    setEditBookingModalOpen(false);
                    setNewChatAction({} as ChatAction);
                    setNewDateTime({} as BookingDateAndTime);
                  }
                  }>
                    &times;
                  </button>
                  <div className="header"> Propose changes to the booking request</div>
                  <div className="content">
                    {' '}
                    You can create changes to the booking and send it to {bookingDetails.clientName} for approval.
                  </div>
                  <div id={'edit-booking-wrapper'}>
                    <FormFields>
                      <FormLabel>Date</FormLabel>
                      <DatePicker
                          selected={dayjs(newDateTime.bookingDate).toDate()}
                          onChange={val => {
                            setNewDateTime({
                              ...newDateTime,
                              bookingDate: dayjs(val).format('YYYY-MM-DD')
                            });
                          }
                          }

                          className={''}
                      />
                      <FormLabel>From: </FormLabel>
                      <Row>
                        <Col lg={6} sm={6} xs={6}>
                          <Select
                              options={allHours}
                              labelKey='label'
                              valueKey='value'
                              placeholder=''
                              value={{value: newDateTime.startHour, label: newDateTime.startHour}}
                              searchable={false}
                              onChange={(value) => {
                                handleTimeChange(value, 'startHour');
                              }}
                              overrides={baseSelectOverrides}
                          />
                        </Col>
                        <Col lg={6} sm={6} xs={6}>
                          <Select
                              options={[{value: 0, label: 0}, {value: 30, label: 30}]}
                              labelKey='label'
                              valueKey='value'
                              placeholder=''
                              value={{value: newDateTime.startMinute, label: newDateTime.startMinute}}
                              searchable={false}
                              onChange={(value) => {
                                handleTimeChange(value, 'startMinute');
                              }}
                              overrides={baseSelectOverrides}
                          />
                        </Col>
                      </Row>
                      <FormLabel>To:</FormLabel>
                      <Row>
                        <Col lg={6} sm={6} xs={6}>
                          <Select
                              options={allHours}
                              labelKey='label'
                              valueKey='value'
                              placeholder=''
                              value={{value: newDateTime.endHour, label: newDateTime.endHour}}
                              searchable={false}
                              onChange={(value) => {
                                handleTimeChange(value, 'endHour');
                              }}
                              overrides={baseSelectOverrides}
                          />
                        </Col>
                        <Col lg={6} sm={6} xs={6}>
                          <Select
                              options={[{value: 0, label: 0}, {value: 30, label: 30}]}
                              labelKey='label'
                              valueKey='value'
                              placeholder=''
                              value={{value: newDateTime.endMinute, label: newDateTime.endMinute}}
                              searchable={false}
                              onChange={(value) => {
                                handleTimeChange(value, 'endMinute');
                              }}
                              overrides={baseSelectOverrides}
                          />
                        </Col>
                      </Row>
                    </FormFields>
                    <DeviderLine/>
                    <div style={{marginBottom: '10px'}}>
                      {extrasReady &&
                          /**
                           * loop over ALL extras
                           * IFF bookingDetail extra contains extra mark it as selected
                           * what does it mean to be selected. it means to be in the newExtras
                           *
                           * end result is we add the selected ones to the action call and thats it
                           */
                          setAllPills()
                      }
                    </div>
                    <DeviderLine/>
                    <CleanerReceipt
                        cleanerGets={newReceipt.cleanerGets}
                        subTotal={newReceipt.subTotal}
                        cleanerFee={newReceipt.fees}
                    />
                  </div>
                  <div>
                  </div>
                  <div className="actions">
                    <Button
                        title={'Propose change'}
                        kind={KIND.primary}
                        onClick={async e => {
                          e.preventDefault();
                          await createBookingAction();
                          setConfirmRejectModalOpen(false);
                        }
                        }
                        overrides={{
                          BaseButton: {
                            style: ({$theme}) => ({
                              height: '48px',
                              borderTopLeftRadius: '3px',
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                              borderBottomLeftRadius: '3px',
                              // color: $theme.colors.red400,
                              width: '100%'
                            }),
                          },
                        }}
                    >Propose changes</Button>

                    <h2>OR</h2>

                    <Button
                        title={'Propose change'}
                        kind={KIND.primary}
                        onClick={async e => {
                          e.preventDefault();
                          await createBookingAction(true);
                          setConfirmRejectModalOpen(false);
                        }
                        }
                        overrides={{
                          BaseButton: {
                            style: ({$theme}) => ({
                              height: '48px',
                              borderTopLeftRadius: '3px',
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                              borderBottomLeftRadius: '3px',
                              backgroundColor: $theme.colors.red400,
                              width: '100%',
                              marginTop: '10px'
                            }),
                          },
                        }}
                    >Apply changes right away</Button>
                  </div>
                </div>
              </Popup>

              {/*MAP*/}
              <div className="mt-8 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 py-5 sm:p-6">
                  <GoogleMapsWrapper>
                    <LoadScript
                      id="script-loader"
                      googleMapsApiKey="AIzaSyCm5ChnsE7FPArmt7EiHsGcp99Z7EqbPTM"
                    >
                      <GoogleMap
                        id='cleaner-workin-map'
                        mapContainerStyle={{
                          height: "100%",
                          width: "100%"
                        }}
                        zoom={11}
                        center={{lat: bookingDetails.coordinates.lat || 0, lng: bookingDetails.coordinates.lng || 0}}
                        options={
                          {
                            fullscreenControl: true,
                            mapTypeControl: false,
                            streetViewControl: false
                          }
                        }

                      >
                        <Marker position={{
                          lat: bookingDetails.coordinates.lat || 0,
                          lng: bookingDetails.coordinates.lng || 0
                        }}/>
                      </GoogleMap>
                    </LoadScript>
                  </GoogleMapsWrapper>
                </div>
              </div>
              {/*PROPERTY              */}
              <div className="mt-8 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 py-5 sm:px-6 text-xl">
                  Property Details
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <AddressDetails
                    address={bookingDetails.address}
                    apartmentNumber={bookingDetails.apartmentNumber || null}
                    entranceInfo={bookingDetails.entranceInfo}
                    otherInfo={bookingDetails.otherInfo}
                    parkingInfo={bookingDetails.parkingInfo}
                    numberOfRooms={bookingDetails.numberOfRooms}
                    numberOfBathrooms={bookingDetails.numberOfBathrooms}
                    squareFootage={bookingDetails.squareFootage}
                    sizeIncludesBasement={bookingDetails.sizeIncludesBasement}
                  />
                </div>
              </div>

              {/*BOOKING*/}
              <div className="mt-8 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                <div className="  flex flex-row justify-around">
                  <div className="px-4 py-5 sm:px-6 text-xl">
                    Booking Details
                  </div>
                  <div className="m-2">
                    <Button
                      onClick={e => {
                        e.preventDefault();
                        addNotification(bookingDetails.id);
                      }}
                    >Ask Admins to look at this booking request</Button>
                  </div>
                </div>

                <div className="px-4 py-5 sm:p-6">

                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Text style={{
                      marginBottom: '10px',
                      fontSize: '18px',
                    }}>{dayjs(bookingDetails.date).format('DD MMM')}, {bookingDetails.cleaningTime}</Text>

                    <Text style={{color: "#056608", fontSize: '18px'}}>${bookingDetails.totalAmount} </Text>
                  </div>
                  <Text>{couponCovers()}</Text>
                  <p style={{marginTop: '0px'}}>For {bookingDetails.clientName} {bookingDetails.recurrance || 'one time booking'}</p>

                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    <BookingStatus
                      status={bookingDetails.currentState}
                    />
                    {(bookingDetails.currentState === 'requested' && (dayjs().isSameOrBefore(dayjs(bookingDetails.date), 'date'))) && (
                      <div id={'accept-reject-wrapper'}>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            acceptCleaningRequest();
                          }
                          }
                          overrides={{
                            BaseButton: {
                              style: ({$theme}) => ({
                                height: '48px',
                                borderTopLeftRadius: '3px',
                                borderTopRightRadius: '3px',
                                borderBottomRightRadius: '3px',
                                borderBottomLeftRadius: '3px',
                                marginRight: '10px'
                              }),
                            },
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          onClick={e => {
                            e.preventDefault();
                            setConfirmRejectModalOpen(true);
                          }
                          }
                          kind={KIND.secondary}
                          overrides={{
                            BaseButton: {
                              style: ({$theme}) => ({
                                height: '48px',
                                borderTopLeftRadius: '3px',
                                borderTopRightRadius: '3px',
                                borderBottomRightRadius: '3px',
                                borderBottomLeftRadius: '3px',
                                color: $theme.colors.red400,
                              }),
                            },
                          }}
                        >
                          Reject
                        </Button>
                      </div>

                    )}
                    {dayjs(bookingDetails.date).isSame(dayjs(), 'date') &&
                        <>
                          {!bookingDetails.startedAt &&
                              <Button
                                  kind={KIND.primary}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setConfirmStartModalOpen(true);

                                  }}
                                  overrides={{
                                    BaseButton: {
                                      style: ({$theme}) => ({
                                        height: '48px',
                                        borderTopLeftRadius: '3px',
                                        borderTopRightRadius: '3px',
                                        borderBottomRightRadius: '3px',
                                        borderBottomLeftRadius: '3px',
                                      }),
                                    },
                                  }}
                              >
                                Start Cleaning
                              </Button>
                          }

                          {(bookingDetails.startedAt && !bookingDetails.stoppedAt) &&
                              <Button
                                  kind={KIND.primary}
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    await stopCleaning();
                                    window.location.reload();
                                  }}
                                  overrides={{
                                    BaseButton: {
                                      style: ({$theme}) => ({
                                        height: '48px',
                                        borderTopLeftRadius: '3px',
                                        borderTopRightRadius: '3px',
                                        borderBottomRightRadius: '3px',
                                        borderBottomLeftRadius: '3px',
                                      }),
                                    },
                                  }}
                              >
                                Stop Cleaning
                              </Button>
                          }
                        </>
                    }
                  </div>
                  {bookingDetails.startedAt && <>
                    <label className="text-base font-semibold text-gray-900">Started at </label>
                    <p className="text-sm text-gray-700">{bookingDetails.startedAt}</p>
                  </>}
                  {bookingDetails.stoppedAt && <>
                    <label className="text-base font-semibold text-gray-900">Stopped at </label>
                    <p className="text-sm text-gray-700">{bookingDetails.stoppedAt}</p>
                  </>}
                  {cleaningCommeneced() && (
                    <div id={'edit-booking-wrapper'} style={{marginBottom: '10px'}}>
                      <DeviderLine/>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          openEditBookingModal();
                        }
                        }
                        kind={KIND.primary}
                        overrides={{
                          BaseButton: {
                            style: ({$theme}) => ({
                              height: '48px',
                              borderTopLeftRadius: '3px',
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                              borderBottomLeftRadius: '3px',
                              width: '100%',
                            }),
                          },
                        }}
                      >
                        Propose changes to the booking
                      </Button>
                    </div>
                  )}

                  <DeviderLine/>
                  <Extras extras={bookingDetails.extras}/>
                </div>
              </div>
              {/*CHAT*/}
              <div className="mt-8 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                <div className="px-4 py-5 sm:p-6">
                  <Chat
                    messagesList={messagesList}
                    submitNewMessageToBooking={submitNewMessageToBooking}
                    conversationId={bookingDetails.conversationId}
                  />
                </div>
              </div>
              {/*CHECKLIST*/}
              {bookingDetails.startedAt &&
                <div className="mt-8 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
                  <div className="px-4 py-5 sm:px-6 text-xl">
                    Cleaning Check List
                  </div>
                  <div className="px-4 py-5 sm:p-6">
                    {generateCheclist()}
                  </div>
                </div>
              }
            </>
        )}
      </>
  );
};

export default Booking;
