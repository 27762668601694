import React, {useEffect, useState} from 'react'
import {del, get, put} from "../../helper/fetch";
import TimeOverridePopup, {TimeOverrideError} from "./TimeOverridePopup";
import {toast} from "react-toastify";
import EditCleanerAvailability from "../../components/EditCleanerAvailability/EditCleanerAvailability";
import CleanerAvailability from "../../components/CleanerAvailability/CleanerAvailability";
import dayjs from "dayjs";
import {useHistory} from "react-router-dom";
import {Cleaner} from "../../settings/bookingTypes";
import {classNames} from "../../helper/util";
import {XCircleIcon} from "@heroicons/react/solid";


type TimeOverride = {
  date_when: string;
  id?: number;
  from: string;
  till: string;
  all_day: boolean;
}

const statuses = {
  accepted: 'text-green-700 bg-green-50 ring-green-600/20',
  paid: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  rejected: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  cancelled: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  canceled: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20'
};

export default function TimeOff() {
  const history = useHistory();
  const reviewsRef = React.useRef(null);
  const [newDateOff, setNewDateOff] = useState<Date | null>(null);
  const [newDateRangeOff, setNewDateRangeOff] = useState({startDate: null, endDate: null});
  const [diologueOpen, setDiologueOpen] = useState(false);
  const [editAvailability, setEditAvailability] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [overrides, setOverrides] = useState<TimeOverride[]>([]);
  const [next30DaysWithOverrides, setNext30DaysWithOverrides] = useState<any[]>([]);
  const [cleaner, setCleaner] = useState<Cleaner | null>(null);
  const [timeOverrideErrors, setTimeOverrideErrors] = useState<TimeOverrideError[]>([]);
  const [bookingByDate, setBookingByDate] = useState<any>({});
  const [payouts, setPayouts] = useState([] as { date: string, amount: number }[]);

  useEffect(() => {
    fetSchedule()
      .then((sched) => fetScheduleOverrides(sched))
      .then(() => fetchBookings())
      .then(() => fetchPayouts());
  }, []);

  const fetSchedule = () => {
    return get(`/me/listing/availability`)
      .then(r => {
        if (r.length === 0) {
          setSchedule(null);
        } else {
          setSchedule(r);
        }
        return r;
      }).catch(e => {
        console.log(e);
        toast.error('Failed to get your availability');
      });
  }

  const fetchBookings = async () => {
    const urlParams = new URLSearchParams({
      fromDate: dayjs().format('YYYY-MM-DD'),
      toDate: dayjs().add(30, 'day').format('YYYY-MM-DD')
    });
    return get(`/bookings?` + urlParams)
      .then(r => {
        let bookingMapByDate = {};
        r.forEach((booking) => {
          if (bookingMapByDate[booking.date]) {
            bookingMapByDate[booking.date].push(booking);
          } else {
            bookingMapByDate[booking.date] = [booking];
          }
        });
        setBookingByDate(bookingMapByDate);
      }).catch(e => {
        console.log(e);
        toast.error('Failed to get your bookings');
      });
  }

  const fetchPayouts = async () => {
    // return get(`/cleanerlistings/${id}/payouts`)
    //   .then(r => {
    //     const payoutsByDate = r.reduce((acc, payout) => {
    //       acc[payout.date] = payout.amount;
    //       return acc;
    //     }, {});
    //     setPayouts(payoutsByDate);
    //     //insert into next30DaysWithOverrides
    //     const smallestDates = next30DaysWithOverrides[0].date;
    //     const largestDates = next30DaysWithOverrides[next30DaysWithOverrides.length - 1].date;
    //     const filteredPayouts = r.filter((payout) => {
    //       const d = dayjs(payout.date);
    //       return (d.isSame(smallestDates) || d.isAfter(smallestDates)) && (d.isBefore(largestDates) || d.isSame(largestDates));
    //     });
    //     filteredPayouts.foreach((payout) => {
    //       const date = dayjs(payout.date);
    //       const day = next30DaysWithOverrides.find((d) => d.date.isSame(date));
    //       if(!day){
    //         //add day to next30DaysWithOverrides
    //         next30DaysWithOverrides.push({date: date, payout: payout.amount});
    //       }
    //     });
    //   }).catch(e => {
    //     console.log(e);
    //     toast.error('Failed to get your payouts');
    //   });
  }

  const fetScheduleOverrides = (schedule) => {
    get(`/me/listing/timeoverride`)
      .then(r => {
        setOverrides(r);
        const sched = schedule;
        const overrides = r;
        const days: dayjs.Dayjs[] = [];
        for (let i = 0; i < 30; i++) {
          let date = dayjs();
          date = date.add(i, 'day');
          days.push(date);
        }
        const schedWithOverride = combineScheduleAndOverrides(sched, overrides, days);
        setNext30DaysWithOverrides(schedWithOverride);
      }).catch(e => {
      console.log(e);
      toast.error('Failed to get your time override');
    });
  }

  function combineScheduleAndOverrides(schedule, overrides, days) {
    if (!schedule || schedule.length === 0) return [];
    let newSchedule = [];
    days.forEach((day) => {
      const dayOfWeek = day.format('dddd').toLowerCase();
      const scheduleForDay = schedule[dayOfWeek];
      newSchedule.push({...scheduleForDay, date: day});
    });

    newSchedule = newSchedule.map((day) => {
      const override = overrides.find((sched) => {
        return sched.date_when === day.date.format('YYYY-MM-DD');
      });
      if (!override) return day;

      //have an override:
      day.overrideId = override.id;
      if (override.all_day) {
        day.overrideDescription = 'All Day Off';
        day = {...day, allDay: true};
      } else {
        day.overrideDescription = `Override: ${override.from} - ${override.till}`;
        day = {
          ...day,
          from: override.from,
          till: override.till
        };
      }
      return day;
    });

    return newSchedule.map((day) => {
      if (!day.allDay) {
        if (day.from === 'none' || day.till === 'none' || day.from === null || day.till === null) {
          day.allDay = true;
        } else {
          //format to am/pm
          const from = day.from.split(':');
          const till = day.till.split(':');
          day.from = `${parseInt(from[0]) % 12} ${parseInt(from[0]) >= 12 ? 'pm' : 'am'}`;
          day.till = `${parseInt(till[0]) % 12} ${parseInt(till[0]) >= 12 ? 'pm' : 'am'}`;
        }
      }
      return day;
    });
  }

  const renderAvailability = () => {
    if (editAvailability) {
      return <EditCleanerAvailability
        availability={schedule}
        onSave={async (newAvailability) => {
          const resp = await put(`/me/listing/availability`, newAvailability);
          if (resp.status !== 200) {
            toast.error('could not update cleaner availability');
          } else {
            toast.success('updated cleaner availability');
            //refetch
            fetSchedule();
            setEditAvailability(false);
          }
        }}
      />
    } else {
      return <CleanerAvailability
        availability={schedule}
      />
    }
  }

  return (
    <div className="relative min-h-screen bg-gray-100">

      <TimeOverridePopup
        open={diologueOpen}
        onClose={(errors) => {
          setTimeOverrideErrors(errors);
          setDiologueOpen(false);
          fetSchedule().then((sched) => fetScheduleOverrides(sched));
        }}
        listingId={null}
      />

      <main className="py-10">

        <div
          className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                    <p className='text-md font-semibold text-gray-900 hover:cursor-pointer hover:text-hsgreen'>My Work
                      Availability</p>
                  </h2>
                  <p className='text-sm font-medium text-gray-500 pb-4'>Set your regular weekly availability</p>

                  {schedule && renderAvailability()}
                </div>
                <div>
                  <div
                    className={"block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg cursor-pointer"}>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                      onClick={() => {
                        setEditAvailability(!editAvailability);
                      }}
                    >
                      Edit My Schedule
                    </button>
                  </div>
                </div>
              </div>
            </section>

            {schedule && <section aria-labelledby="DaysOff" ref={reviewsRef}>
              <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                      Time Off / Time Correction
                    </h2>
                    <p className='text-sm font-medium text-gray-500'>&gt; Add any days and times for which you are
                      unavailable to work or want to be available for hours different from your normal schedule.
                    </p>
                    <p className='text-sm font-medium text-gray-500 pt-2'>&gt; You can select single dates, or you can
                      select multiples dates</p>
                  </div>
                  <div className="px-4 pt-2 sm:px-6" style={{marginBottom: '40px'}}>

                    <div className="divide-y divide-gray-100 flex place-content-center">
                      <button
                          type="button"
                          className="inline-flex w-full items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                          onClick={() => {
                            setDiologueOpen(true);
                            setTimeOverrideErrors([]);
                          }}
                      >
                        Add Time Off
                      </button>
                    </div>

                    {timeOverrideErrors && timeOverrideErrors.length > 0 && <div className='bg-red-100 w-full'>
                      <p className='text-sm font-medium text-gray-500 pt-2'>Not all dates were saved due to existing
                        bookings:</p>
                      {timeOverrideErrors.map((error) => (
                        <div key={error.date}
                             onClick={() => {
                               window.open(`/bookings/${error.bookingId}`);
                             }}
                             className="text-red-500 text-sm font-medium underline hover:cursor-pointer hover:text-red-700"
                        >
                          {error.date && dayjs(error.date).format('YYYY-MM-DD')}: {error.reason}
                        </div>
                      ))}
                    </div>}
                  </div>

                </div>

              </div>
            </section>}

            <section aria-labelledby="nextthirtydays" ref={reviewsRef}>
              <div className="bg-white shadow sm:rounded-lg overflow-hidden">
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                      Next 30 days
                    </h2>
                  </div>
                  <div className="px-4 pt-2 sm:px-6" style={{marginBottom: '40px'}}>

                    <ul role="list" className="divide-y divide-gray-100 m-2">
                      {payouts && bookingByDate && next30DaysWithOverrides.map((day) => (
                        <li
                          key={day.date.format('YYYY-MM-DD')}
                          className="flex items-center justify-between gap-x-6 py-5"
                        >
                          <div className="min-w-0">
                            <div className="flex items-start">
                              <p
                                className="text-md font-semibold text-gray-900"
                              >
                                {day.date.format('dddd, MMMM D')}
                              </p>
                            </div>
                            <div className="flex flex-col items-start">
                              <p
                                className='text-gray-900 text-md rounded-md mt-0.5 py-0.5 font-medium'
                              >
                                Availability: {day.allDay ? 'All Day Off' : `${day.from} - ${day.till}`}
                              </p>
                              {day.overrideId && <p
                                  className='flex items-center gap-x-2 text-xs leading-5 text-gray-500'
                              >
                                (from override)
                              </p>}
                              {!day.overrideId && <p
                                  className='flex items-center gap-x-2 text-xs leading-5 text-gray-500'
                              >
                                (from schedule)
                              </p>}
                            </div>
                            {day.overrideId && <div className="flex items-start">
                              <button type={"button"}
                                      className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                      onClick={() => {
                                        console.log(`delete override ${day.overrideId}`);
                                        del(`/me/listing/timeoverride/${day.overrideId}`)
                                          .then(r => {
                                            if(r.status === 200){
                                              toast.success('Override cleared');
                                              fetSchedule().then((sched) => fetScheduleOverrides(sched));
                                            }
                                            return r;
                                          }).catch(e => {
                                          console.log(e);
                                          toast.error('Failed to clear override');
                                        });
                                      }}
                              >
                                Clear override
                                <XCircleIcon aria-hidden="true" className="-mr-0.5 h-5 w-5" />
                              </button>
                            </div>
                            }
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                              <p className="whitespace-nowrap">Bookings</p>
                            </div>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                              {bookingByDate && bookingByDate[day.date.format('YYYY-MM-DD')] && bookingByDate[day.date.format('YYYY-MM-DD')].map((booking) => (
                                <p
                                  key={booking.id}
                                  className={classNames(
                                    statuses[booking.status],
                                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset hover:cursor-pointer'
                                  )}
                                  onClick={() => {
                                    window.open(`/bookings/${booking.id}`);
                                  }}
                                >
                                  #{booking.id} - {booking.time} ({booking.status})
                                </p>
                              ))}
                            </div>

                            {payouts && payouts.length > 0 && <>
                              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                <p className="whitespace-nowrap">Payout</p>
                              </div>
                              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                {payouts && payouts[day.date.format('YYYY-MM-DD')] && (
                                  <p
                                    key={`payout-${day.date.format('YYYY-MM-DD')}`}
                                    className={classNames(
                                      'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset hover:cursor-pointer'
                                    )}
                                  >
                                    ${payouts[day.date.format('YYYY-MM-DD')]}
                                  </p>
                                )}
                              </div>
                            </>
                            }
                          </div>
                        </li>
                      ))}
                    </ul>

                  </div>
                </div>

              </div>
            </section>

          </div>
        </div>
      </main>
    </div>
  )
}