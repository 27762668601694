import MobileDetect from 'mobile-detect';
export function useDeviceType() {
  const md = new MobileDetect(window.navigator.userAgent);
  // const mobile = true;
  const mobile = md.mobile();
  const tablet = md.tablet();
  const desktop = !(mobile && tablet) ? true : null;
  return {
    mobile,
    tablet,
    desktop,
  };
}
