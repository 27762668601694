import React, {useState} from 'react';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { CloseIcon, PencilIcon } from '../AllSvgIcon';

const ExtrasWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
`;

const ExtraPillWrapper = styled.div`
  border-color: ${themeGet('colors.darkRegular', '#77798c')};
  border-style: dashed;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 400px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 2px;
  padding-top: 2px;
  border-width: thin;
  margin: 2px;

  &.active {
    border: 2px solid ${themeGet('colors.primary', '#009E7F')};
    background-color: #ffffff;
    padding-bottom: 1px;
    padding-top: 1px;
  }
  
  &.disabled {
    
  }
`;

type ExtraPillProps = {
  id: number;
  name: string;
  content: string;
  disabled?: boolean;
  checked?: boolean;
  onChecked: (number) => void;
  onUnChecked: (number) => void;
};

const ExtraPill: React.FC<ExtraPillProps> = ({
                                               id,
                                               name,
                                               content,
                                               disabled,
                                               checked,
                                               onChecked,
                                               onUnChecked,
                                             }) => {
  const [checkedSelected, setChecked] =useState(checked);
  const handleClick = () => {
    if(checkedSelected) {
      onUnChecked({id: id, name: name});
    }else{
      onChecked({id: id, name: name});
    }
    setChecked(!checkedSelected);
  }
  return (
      <ExtrasWrapper>
        <ExtraPillWrapper
            key={`${name}-${id}`}
            className={`label ${checkedSelected ? 'active' : 'not_active'} ${disabled ? 'disabled': null}`}
            onClick={handleClick}
        >
          {name} {content}
        </ExtraPillWrapper>
      </ExtrasWrapper>
  );
};

export default ExtraPill;