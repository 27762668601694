import React, {createContext, useReducer} from "react";

type DrawerState = {
  isOpen: boolean;
  drawerComponent: any;
  data: any;
}
type Action = any;

const reducer = (state: DrawerState, action: Action) => {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return {
        ...state,
        isOpen: true,
        drawerComponent: action.drawerComponent,
        data: action.data,
      };
    case 'CLOSE_DRAWER':
      return {
        ...state,
        isOpen: false,
        drawerComponent: null,
        data: null,
      };
    default:
      return state;
  }
}

export const DrawerContext = createContext<Partial<any>>({});

type ProfileProviderProps = {
  initData: DrawerState;
};
export const DrawerProvider: React.FunctionComponent<ProfileProviderProps> = ({
                                                                                children,
                                                                                initData,
                                                                              }) => {
  const [state, dispatch] = useReducer(reducer, initData);
  return (
    <DrawerContext.Provider value={{state, dispatch}}>
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;