import React from 'react';
import {getCookie, removeCookie, setCookie} from "../helper/session";

type AuthProps = {
  isAuthenticated: boolean;
  cleanerId: number;
  authenticate: Function;
  signout: Function;
  setCleanerID: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  // const token = localStorage.getItem('pickbazar_token');
  const token = getCookie('access_token');
  // JWT decode & check token validity & expiration.
  if (token) return true;
  return false;
};

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  const [cleanerId, setId] = React.useState(0);
  function authenticate(extraParams, cb) {
    makeAuthenticated(true);
    cb();
  }

  function setCleanerID(id){
    setId(id);
  }

  function signout(cb) {
    makeAuthenticated(false);
    removeCookie('access_token');
    removeCookie('session');
    setTimeout(cb, 100);
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        cleanerId,
        setCleanerID,
        authenticate,
        signout,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
