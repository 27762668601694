import React, {useContext, useEffect, useState} from 'react';
import {createThemedUseStyletron, styled, withStyle} from 'baseui';
import {Col as Column, Grid, Row as Rows,} from '../../components/FlexBox/FlexBox';
import Select from '../../components/Select/Select';

import {Header, Heading, Wrapper} from '../../components/WrapperStyle';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {StyledCell, StyledHeadCell, StyledTable, TableWrapper,} from './Bookings.style';
import NoResult from '../../components/NoResult/NoResult';
import {get} from "../../helper/fetch";
import Button from "../../components/Button/Button";
import {DrawerContext} from "../../context/DrawerContext";
import {useDeviceType} from "../../settings/useDeviceType";
import {useHistory} from 'react-router-dom';


const exampleOrders = {orders:[{
  id: 1,
  clientId: 'asd',
  clientName: 'Nick Ross',
    cleanerId: 'asdasd',
  cleanerName: 'Cleaner Ross',
  bookingDate: '',
  address: 'adress',
  amount: 123.22,
  paymentMethod: 'stripe',
  status: 'accepted',
    recurrance: 'recurrance'
}]};

type CustomThemeT = { red400: string; textNormal: string; colors: any };
const themedUseStyletron = createThemedUseStyletron<CustomThemeT>();

const Status = styled('div', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  display: 'flex',
  alignItems: 'center',
  lineHeight: '1',
  textTransform: 'capitalize',

  ':before': {
    content: '""',
    width: '10px',
    height: '10px',
    display: 'inline-block',
    borderRadius: '10px',
    backgroundColor: $theme.borders.borderE6,
    marginRight: '10px',
  },
}));

const Col = withStyle(Column, () => ({
  '@media only screen and (max-width: 767px)': {
    marginBottom: '20px',

    ':last-child': {
      marginBottom: 0,
    },
  },
}));

const Row = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    alignItems: 'center',
  },
}));

const SmallRow = withStyle(Rows, () => ({
  '@media only screen and (min-width: 768px)': {
    margin: '5px',
    alignItems: 'center',
  },
}));

const statusSelectOptions = [
  { value: 'All', label: 'All' },
  { value: 'Requested', label: 'Requested' },
  { value: 'Accepted', label: 'Accepted' },
  // { value: 'Completed', label: 'Completed' },
];
const limitSelectOptions = [
  { value: 7, label: 'Last 7 orders' },
  { value: 15, label: 'Last 15 orders' },
  { value: 30, label: 'Last 30 orders' },
];

export default function Bookings() {
  let history = useHistory();
  const [checkedId, setCheckedId] = useState([]);
  const { desktop, mobile } = useDeviceType();

  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("all");
  const [limit, setLimit] = useState([]);
  const [search, setSearch] = useState([]);

  const [error, setError] = useState(null);
  const [bookings, setBookigns] = useState([]);
  const [cleaners, setCleaners] = useState([]);
  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const bookings: any = get('/bookings')
      .then(bs => {
        setBookigns(bs);
      }).catch(e => {
        setError('could not fetch bookings')
      });
  }, []);

  const {state, dispatch} = useContext(DrawerContext);

  const openBookingDetailDrawer = async (bookingId) => {
    const bookingDetails = await get('/bookings/'+bookingId);
    dispatch({
      type: 'OPEN_DRAWER',
      drawerComponent: 'BOOKING_DETAILS_FORM',
      data: {bookingDetails, cleaners},
    })
  };

  const data = exampleOrders;
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  function handleStatus({ value }) {
    setStatus(value);
    setSelectedStatus(value[0].value);
    if (value.length) {
      get("/bookings?" + new URLSearchParams({
        "size": (20).toString(),
        "page": (0).toString(),
        "status": value[0].value
      })) .then(bs => {
        setBookigns(bs);
      }).catch(e => {
        setError('could not fetch bookings')
      });
    }
  }

  function handleLimit({ value }) {
    setLimit(value);
    if (value.length) {
    } else {
    }
  }

  function handleCheckbox(event) {
    const { name } = event.currentTarget;
    if (!checkedId.includes(name)) {
      setCheckedId(prevState => [...prevState, name]);
    } else {
      setCheckedId(prevState => prevState.filter(id => id !== name));
    }
  }
  return (
    <Grid fluid={true}>
      <Row>
        <Col md={12}>
          <Header
            style={{
              marginBottom: 30,
              boxShadow: '0 0 8px rgba(0, 0 ,0, 0.1)',
            }}
          >
            <Col md={3} xs={12}>
              <Heading>Bookings</Heading>
            </Col>
            <Col md={9} xs={12}>
              <Row>
                <Col md={3} xs={12}>
                  <Select
                    options={statusSelectOptions}
                    labelKey='label'
                    valueKey='value'
                    placeholder='Status'
                    value={status}
                    searchable={false}
                    onChange={handleStatus}
                  />
                </Col>

                {/*<Col md={6} xs={12}>*/}
                {/*  <Input*/}
                {/*    value={search}*/}
                {/*    placeholder='Ex: Search By Client Name'*/}
                {/*    onChange={() => {}}*/}
                {/*    clearable*/}
                {/*  />*/}
                {/*</Col>*/}
              </Row>
            </Col>
          </Header>
          {mobile ? (
						<Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
              {bookings ? (
                bookings.length ? (
                  bookings
                    .map( booking => {
                      return <div style={{boxShadow: 'rgba(18, 18, 18, 0.54) 0px 0px 1px'}}>
                        <Row >
                          <Col md={12} sm={12} xs={12} key={booking.id}>
                            <Row style={{marginTop: '3vh', marginLeft:'1vh', marginRight: '1vh'}}>
                              <Col md={6} sm={6} xs={6}>
                                Id: {booking.id}
                              </Col>
                              <Col md={6} sm={6} xs={6}>
                                For {booking.clientName}
                              </Col>
                            </Row>
                            <Row style={{marginLeft:'1vh', marginRight: '1vh'}}>
                              <Col md={6} sm={6} xs={6}>
                                <div>
                                  <b style={{marginBottom:'5px'}}>{booking.date}</b>
                                </div>
                                 {booking.cleaningTime}
                              </Col>
                              <Col md={6} sm={6} xs={6}>
                                <Status>{booking.status}</Status>
                              </Col>
                            </Row>
                            <Row style={{marginLeft:'1vh', marginRight: '1vh', marginBottom: '1vh'}}>
                              <Col md={12} sm={12} xs={12}>
                                <Button
                                  onClick={() => history.push('/bookings/'+booking.id)}
                                  disabled={false}
                                  overrides={{
                                    BaseButton: {
                                      style: ({$theme}) => ({
                                        width: "100%",
                                        marginLeft: "auto",
                                        borderTopLeftRadius: "3px",
                                        borderTopRightRadius: "3px",
                                        borderBottomLeftRadius: "3px",
                                        borderBottomRightRadius: "3px"
                                      })
                                    }
                                  }}
                                >  Details
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        </div>

                    })
                ) : (
                  <NoResult
                    hideButton={false}
                    style={{
                      gridColumnStart: '1',
                      gridColumnEnd: 'one',
                    }}
                  />
                )
              ) : null}
						</Wrapper>
          ) : (
            <Wrapper style={{ boxShadow: '0 0 5px rgba(0, 0 , 0, 0.05)' }}>
              <TableWrapper>
                <StyledTable $gridTemplateColumns='minmax(80px, 80px) minmax(70px, 70px) minmax(150px, auto) minmax(100px, auto) minmax(100px, max-content) minmax(150px, auto) minmax(150px, auto) minmax(150px, auto) minmax(150px, auto)'>
                  <StyledHeadCell>
                    Details
                  </StyledHeadCell>
                  <StyledHeadCell>ID</StyledHeadCell>
                  <StyledHeadCell>Customer Name</StyledHeadCell>
                  <StyledHeadCell>Date</StyledHeadCell>
                  <StyledHeadCell>Time</StyledHeadCell>
                  <StyledHeadCell>Property Address</StyledHeadCell>
                  <StyledHeadCell>Amount</StyledHeadCell>
                  <StyledHeadCell>Recurrence</StyledHeadCell>
                  <StyledHeadCell>Status</StyledHeadCell>

                  {bookings ? (
                    bookings.length ? (
                      bookings
                        .map( booking => (
                          // .map(item => Object.values(item))
                          // .map((row, index) => (
                          <React.Fragment key={booking.id}>
                            <StyledCell>
                              <Button
                                onClick={() =>
                                  history.push('/bookings/'+booking.id)
                                }
                                disabled={false}
                                overrides={{
                                  BaseButton: {
                                    style: ({$theme}) => ({
                                      width: "120%",
                                      marginLeft: "auto",
                                      borderTopLeftRadius: "3px",
                                      borderTopRightRadius: "3px",
                                      borderBottomLeftRadius: "3px",
                                      borderBottomRightRadius: "3px"
                                    })
                                  }
                                }}
                              >  Details
                              </Button>
                            </StyledCell>
                            <StyledCell>{booking.id}</StyledCell>
                            <StyledCell>{booking.clientName}</StyledCell>
                            <StyledCell>
                              <p >{dayjs(booking.date).format('DD MMM')}</p>
                            </StyledCell>
                            <StyledCell>
                              {booking.cleaningTime}
                            </StyledCell>
                            <StyledCell>{booking.apartmentNumber && `#${booking.apartmentNumber}`} {booking.address}</StyledCell>
                            <StyledCell>${booking.amount}</StyledCell>
                            <StyledCell>{booking.recurrance}</StyledCell>
                            <StyledCell style={{ justifyContent: 'center' }}>
                              <Status
                                className={
                                  /* booking.status.toLowerCase() === 'delivered'
                                     ? sent
                                     : booking.status.toLowerCase() === 'pending'
                                     ? paid
                                     : booking.status.toLowerCase() === 'processing'
                                     ? processing
                                     : booking.status.toLowerCase() === 'failed'
                                     ? failed
                                     :*/ ''
                                }
                              >
                                {booking.status}
                              </Status>
                            </StyledCell>
                          </React.Fragment>
                        ))
                    ) : (
                      <NoResult
                        hideButton={false}
                        style={{
                          gridColumnStart: '1',
                          gridColumnEnd: 'one',
                        }}
                      />
                    )
                  ) : null}
                </StyledTable>
              </TableWrapper>
            </Wrapper>
          )}

        </Col>
      </Row>
    </Grid>
  );
}
