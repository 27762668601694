import React from 'react';
import {Details} from "./AddressDetailsStyle";
import houseLogo from '../63-home-outline.png';
import requested from "../requested.gif";
import {Wrapper} from "../BookingStatus/BookingStatus.style";

type props = {
  address: string;
  apartmentNumber: string;
  entranceInfo?: string;
  otherInfo?: string;
  parkingInfo?: string;
  numberOfRooms?: number;
  numberOfBathrooms?: number;
  squareFootage?: number;
  sizeIncludesBasement: boolean;
}

const AddressDetails: React.FC<props> = ({address, apartmentNumber, entranceInfo, otherInfo, parkingInfo, numberOfRooms, numberOfBathrooms, squareFootage, sizeIncludesBasement}: props) => {
  return (
      <Details>
          <h4 className={"text-lg font-semibold text-gray-900"}>Address</h4>
          {apartmentNumber && <p className={"text-md text-gray-700"}>#{apartmentNumber}</p>}<p className={"text-md text-gray-700"}>{address}</p>
          <h4 className={"text-lg font-semibold text-gray-900"}>Entrance info</h4>
          <p className={"text-md text-gray-700"}>{entranceInfo || 'missing'}</p>
          <h4 className={"text-lg font-semibold text-gray-900"}>Parking info</h4>
          <p className={"text-md text-gray-700"}>{parkingInfo || 'missing'}</p>
          <h4 className={"text-lg font-semibold text-gray-900"}>Number of rooms</h4>
          <p className={"text-md text-gray-700"}>{numberOfRooms || 'missing'}</p>
          <h4 className={"text-lg font-semibold text-gray-900"}>Number of bathrooms</h4>
          <p className={"text-md text-gray-700"}>{numberOfBathrooms || 'missing'}</p>
          <h4 className={"text-lg font-semibold text-gray-900"}>Square footage</h4>
          <p className={"text-md text-gray-700"}>{squareFootage || 'missing'}</p>
          <h4 className={"text-lg font-semibold text-gray-900"}>Other info</h4>
          <p className={"text-md text-gray-700"}>{otherInfo || 'missing'}</p>
      </Details>
  )
}

export default AddressDetails;