import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

import { setCookie } from './session';
import { post } from './fetch';
import firebaseConfig from "../firebase.config";


class Firebase {
  constructor() {
    initializeApp(firebaseConfig);
    this.auth = getAuth();
  }

  //
  async login(email, password) {
    try {
      const firebaseAuth = await signInWithEmailAndPassword(this.auth, email, password);
      const token = await firebaseAuth.user.getIdToken();
      const homespritzAuth = await post('/me/auth', { idToken: token });
      if (homespritzAuth.status !== 200) {
        return Promise.resolve(false);
      } else {
        const body = await homespritzAuth.json();
        setCookie('access_token', body.authToken);
        await this.auth.signOut();
        return Promise.resolve(true);
      }
    } catch (e) {
      return Promise.resolve(false);
    }
  }

  async singupSuccess(singUpData) {
    try {
      const signUpResponse = await post('/me', singUpData);
      if (signUpResponse.status !== 201) {
        return false;
      }
      if (signUpResponse.body && signUpResponse.body.errors) {
        return false;
      }
      const sR = await signUpResponse.json();
      setCookie('access_token', sR.authToken);
      return true;
    } catch (e) {
      return true;
    }
  }

  async changePassword(email) {
    try {
      const resp = await sendPasswordResetEmail(this.auth, email);
    } catch (e) {}
  }

  logout() {}
}

export default new Firebase();
