import React from 'react'
import {CheckCircleIcon} from "@heroicons/react/solid";
import canva_timeoff_design from "../../image/canva_timeoff_design.jpg";

const benefits = [
  'View and manage your schedule',
  'Appear in search results more accurately',
  'View next 30 days of work',
  'Change your availability for specific days and hours',
]

const faqs = [
  {
    question: 'How do you make holy water?',
    answer:
      'You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.',
  },
  // More questions...
]

export default function NotificationsPage() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div
            className="mx-auto flex max-w-2xl flex-col gap-16 bg-white/5 px-6 py-16 ring-1 ring-white/10 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
            <img
              alt=""
              src={canva_timeoff_design}
              // src="https://images.unsplash.com/photo-1519338381761-c7523edc1f46?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
              className="h-96 w-full flex-none rounded-2xl object-cover shadow-xl lg:aspect-square lg:h-auto lg:max-w-sm"
            />
            <div className="w-full flex-auto">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">New Feature: Time Off </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                You can now select your time off in the app. This will help us better match you with clients and ensure
                you have the time you need to rest and recharge. Here are some of the benefits of using this new
                feature:
              </p>
              <ul
                role="list"
                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2"
              >
                {benefits.map((benefit) => (
                  <li key={benefit} className="flex gap-x-3">
                    <CheckCircleIcon aria-hidden="true" className="h-7 w-5 flex-none"/>
                    {benefit}
                  </li>
                ))}
              </ul>
              <div className="mt-10">
                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">How to Set Your Days Off: </h2>
                <ul
                  className='list-decimal mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-white sm:grid-cols-2'>
                  <li className='text-white'><p className="mt-3 text-gray-300">
                    From the main menu, click on 'My Schedule'.
                  </p></li>
                  <li className='text-white'><p className="mt-3 text-gray-300">
                    The first section displays your regular weekly availability.
                  </p></li>
                  <li className='text-white'><p className="mt-3 text-gray-300">
                    Scroll down to the second section labeled 'Time Off / Time Correction'.
                  </p></li>
                  <li className='text-white'><p className="mt-3 text-gray-300">
                    Click on the 'Add time off' button.
                  </p></li>
                  <li className='text-white'><p className="mt-3 text-gray-300">
                    Choose the specific date or a range of dates you wish to take off.
                  </p></li>
                  <li className='text-white'><p className="mt-3 text-gray-300">
                    Decide whether you want to mark these days as unavailable or adjust the hours you are available.
                  </p></li>
                  <li className='text-white'><p className="mt-3 text-gray-300">
                    To mark the days as unavailable, click 'Mark days as Off'.
                  </p></li>
                  <li className='text-white'><p className="mt-3 text-gray-300">
                    To change your available hours, click 'Modify hours for these dates'.
                  </p></li>
                </ul>
              </div>

              {/*<div className="bg-white">*/}
              {/*  <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">*/}
              {/*    <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">*/}
              {/*      <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked*/}
              {/*        questions</h2>*/}
              {/*      <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">*/}
              {/*        {faqs.map((faq) => (*/}
              {/*          <Disclosure key={faq.question} as="div" className="pt-6">*/}
              {/*            <dt>*/}
              {/*              <Disclosure.Button*/}
              {/*                className="group flex w-full items-start justify-between text-left text-gray-900">*/}
              {/*                <span className="text-base font-semibold leading-7">{faq.question}</span>*/}
              {/*                <span className="ml-6 flex h-7 items-center">*/}
              {/*        <PlusCircleIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden"/>*/}
              {/*        <MinusCircleIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden"/>*/}
              {/*      </span>*/}
              {/*              </Disclosure.Button>*/}
              {/*            </dt>*/}
              {/*            <Disclosure.Panel as="dd" className="mt-2 pr-12">*/}
              {/*              <p className="text-base leading-7 text-gray-600">{faq.answer}</p>*/}
              {/*            </Disclosure.Panel>*/}
              {/*          </Disclosure>*/}
              {/*        ))}*/}
              {/*      </dl>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>

        </div>
      </div>
      </div>
      )
   }