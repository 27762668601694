import React from "react";
import {Text} from "../../containers/BookingDetailForm/BookingDetailsForm.style";

type props = {
  cleanerGets: number;
  subTotal: number;
  cleanerFee: number;
}

const CleanerReceipt: React.FC<props> = ({subTotal, cleanerFee,cleanerGets}) => {
  return (<div  style={{display:'flex', flexDirection:'column'}}>
    {/*<div style={{marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>*/}
    {/*  <Text style={{*/}
    {/*    marginBottom: '10px',*/}
    {/*    fontSize: '18px',*/}
    {/*  }}>Subtotal: </Text>*/}
    {/*  <Text style={{fontSize: '18px'}}>${subTotal}</Text>*/}
    {/*</div>*/}
    {/*<div style={{marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>*/}
    {/*  <Text style={{*/}
    {/*    marginBottom: '10px',*/}
    {/*    fontSize: '18px',*/}
    {/*  }}>Service Fee: </Text>*/}
    {/*  <Text style={{fontSize: '18px'}}>${cleanerFee}</Text>*/}
    {/*</div>*/}
    <div style={{marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
      <Text style={{
        marginBottom: '10px',
        fontSize: '18px',
      }}>New total for you: </Text>
      <Text style={{color: "#056608", fontSize: '18px'}}>${cleanerGets}</Text>
    </div>
  </div>);
}

export default CleanerReceipt;