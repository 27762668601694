import {styled} from 'baseui';

export const GoogleMapsWrapper = styled("div", () => ({
  width: '100%',
  height: '250px'
}));

export const DrawerTitle = styled("h2", ({ $theme }) => ({
  ...$theme.typography.fontBold18,
  margin: 0,
  color: $theme.colors.textDark,
  "@media only screen and (max-width: 991px)": {
    marginLeft: "10px",
  }
}));

export const DeviderLine = styled('div', ({$theme}) => ({
    borderBottom: '1px solid',
    borderBottomColor: $theme.colors.lightGrey,
    marginBottom: '10px'
  })
);


export const baseSelectOverrides = {
  Placeholder: {
    style: ({$theme}) => {
      return {
        ...$theme.typography.fontBold14,
        color: $theme.colors.textNormal,
      };
    },
  },
  DropdownListItem: {
    style: ({$theme}) => {
      return {
        ...$theme.typography.fontBold14,
        color: $theme.colors.textNormal,
      };
    },
  },
  OptionContent: {
    style: ({$theme, $selected}) => {
      return {
        ...$theme.typography.fontBold14,
        color: $selected
            ? $theme.colors.textDark
            : $theme.colors.textNormal,
      };
    },
  },
  SingleValue: {
    style: ({$theme}) => {
      return {
        ...$theme.typography.fontBold14,
        color: $theme.colors.textNormal,
      };
    },
  },
  Popover: {
    props: {
      overrides: {
        Body: {
          style: {zIndex: 1000},
        },
      },
    },
  },
};